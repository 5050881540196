import React, {
  useState,
  useEffect,
} from 'react'

// import './App.css'

import nafnalisti from './data/nafnalisti_dr.json'
import { nofn as valinnofn } from './data/nafnadb.json'

import common from './assets/common'

function App() {
  const [chosenNames, setChosenNames] = useState([])

  const combineNames = () => {
    // ivPMlohCjxVosCF0PdoIuFE3vHG2 -> Kristin
    // HM1HszY4EyM0526I7JfQAWVXScF3 -> Karl
    const chosenNamesKristin = valinnofn.ivPMlohCjxVosCF0PdoIuFE3vHG2.chosen
    const chosenNamesKarl = valinnofn.HM1HszY4EyM0526I7JfQAWVXScF3.chosen

    const cnKarl = chosenNamesKarl.filter((id, idx) => !(chosenNamesKarl.indexOf(id) !== idx)).map((id) => ({ 
      id,
      owner: 0,
      dup: chosenNamesKristin.includes(id),
    }))

    const cnKristin = chosenNamesKristin.filter((id) => (!chosenNamesKarl.includes(id))).map((id) => ({
      id,
      owner: 1,
      dup: false,
    }))

    const allNames = cnKarl.concat(cnKristin)
    const names = allNames.map((val) => ({
      ...val,
      nafn: nafnalisti.find(({ id }) => (val.id === id)).nafn,
    }))

    // console.log(names)

    setChosenNames(names)
  }

  useEffect(() => {
    combineNames()
  }, [])

  return (
    <div style={{ backgroundColor: common.pirateBlack, fontFamily: 'Futura' }}>
      <div style={{ margin: '0 auto', padding: 12, width: '85%' }}>
        {chosenNames.sort((a, b) => a.nafn.localeCompare(b.nafn)).sort((a, b) => (a.dup === b.dup) ? 0 : a.dup ? -1 : 1).map((item, key) => (
            <div
              // key={(item) => `${item.id.toString()}${Math.random() * 9999}`}
              key={item.id.toString()}
              // style={[
              //   styles.listItem,
              //   // (item.dup) && { backgroundColor: common.babyBlue }
              // ]}
            >
            {/* {item && (<> */}
              {(item.dup) ? (
              <div>
                {/* <span style={{ width: 12, paddingRight: 12, textAlign: 'center', color: common.monument }}>{key + 1}</span> */}
                <span
                  // style={[
                  //   styles.unwanted,
                  //   (item && item.owner === 1) ? styles.kusa : styles.kalli,
                  //   { color: common.provence }
                  // ]}
                  style={{ ...styles.unwanted, color: common.provence }}
                >
                  <span>{item.nafn}</span>
                  <span style={{ color: common.darkMonument, userSelect: 'none' }}> Kristínarson Karlsson</span>
                </span>
              </div>
              ) : (
              <div style={{ backgroundColor: common.pirateBlack }}>
                <span
                  // style={styles.unwanted}
                  style={{ ...styles.unwanted, ...((item && item.owner === 1) ? styles.kusa : styles.kalli) }}
                >
                  {item && item.nafn} <span style={{ color: common.darkMonument, userSelect: 'none' }}>{item.owner === 1 ? 'Kristínarson' : 'Karlsson'}</span>
                </span> 
              </div>
              )}
            {/* </>)} */}
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  unwanted: {
    color: common.babyBlue,
    fontSize: 18,
    // lineHeight: 32,
    padding: 12,
  },
  kusa: {
    color: common.honey,
  },
  kalli: {
    color: common.mango,
  },
  listItem: {
    width: '100%',
    flex: 1,
    marginHorizontal: 2,
    padding: 0,
    // marginVertical: 12,
    backgroundColor: common.pirateBlack,
    // borderRadius: 8,
    borderBottomWidth: 1,
    borderColor: common.nightSky,
  },
  PageLinkcontainer: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
  moveOverRight: {
    backgroundColor: common.limePunch,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  moveOverRightText: {
    color: common.honey,
    fontSize: 20,
    textAlign: 'right',
    paddingRight: 20,
    // alignSelf: 'center',
  },
  moveOverLeft: {
    backgroundColor: common.rhubarb,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  moveOverLeftText: {
    color: common.honey,
    fontSize: 20,
    textAlign: 'left',
    paddingLeft: 20,
    // alignSelf: 'center',
  },
  noNames: {
    fontSize: 24,
    textAlign: 'center',
    color: common.nightSky,
    marginTop: 240,
  },
  filter: {
    fontSize: 16,
    padding: 12,
    backgroundColor: common.nightSky,
  },
}

export default App
