const colors = {
  // https://www.pantone.com/color-intelligence/color-of-the-year/color-of-the-year-2020-palette-exploration
  classicBlue: '#144d7f',
  provence: '#678ec4',
  babyBlue: '#b6c7d3',
  monument: '#84898c',
  cornhusk: '#f1d6b0',
  pirateBlack: '#2a2a35',
  rhubarb: '#762130',
  nightSky: '#363838',
  stucco: '#a58d80',
  pinkTint: '#dbcbbe',
  jasmineGreen: '#80c74e',
  limePunch: '#c0d53a',
  mango: '#b65e45',
  honey: '#b99140',
  redViolet: '#a25876',
  lilacSashet: '#e8aeca',
  opalGrey: '#a49e9e',

  // Other:
  lightWhite: '#c0c6cb',
  darkMonument: '#54595c',
}

export default colors
